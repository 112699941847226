import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
// window.BaseUrl = "https://demo.medvantage.tech:7103"
// window.BaseUrl = "http://admin.umrahfare.in"
window.BaseUrl = "https://api.stufithealth.app"
// window.BaseUrl = "https://localhost:7215"

window.userId = window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")).responseValue.userId : null
window.roleId = window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")).responseValue.roleId : null
window.token = window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")).token : null
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
