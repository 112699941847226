async function UserLogin(obj) {
    let url = window.BaseUrl + `/api/Users/UserLogin?userName=${obj.userName}&password=${obj.password}`;
    let head = {
        'Content-Type': 'application/json',
        accept: '*/*',
    }
    try {
        let response =
            await fetch(url, {
                method: 'POST',
                headers: head,
                body: JSON.stringify({})
            })
                .then((res) => res.json({}))
                .then(data => data)
                .catch(error => error)
        return response;
    }
    catch (e) {
        return { status: 0, message: "Server Down!!" }
    }

}
export default UserLogin;