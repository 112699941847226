import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from '../pages/Login/Login'
import CommonLayout from '../layouts/CommonLayout'
import Dashboard from '../pages/Dashboard/Dashboard'
import StudentList from '../pages/Student/StudentList'
import ImportFile from '../pages/Student/ImportFile'
import AddSchool from '../pages/School/AddSchool'
import SchoolList from '../pages/School/SchoolList'
import PDFFile from '../pages/PDFFile'
import AddAdmin from '../pages/Admin/AddAdmin'
import AdminList from '../pages/Admin/AdminList'
import AssignSchoolAdmin from '../pages/AssignSchoolAdmin/AssignSchoolAdmin'
import StudentCard from '../pages/StudentCard'
import GetReport from '../pages/GetReport'
import Authentication from './Authentication'
import VideoTestimonial from '../pages/Testimonial/VideoTestimonial'
import ImageTestimonial from '../pages/Testimonial/ImageTestimonial'
import AddTestimonial from '../pages/Testimonial/AddTestimonial'
import SevereList from '../pages/Severe/SevereList'
import StudentForm from '../pages/Student/StudentForm'

export default function Routing() {
    return (
        <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/home/' element={<Authentication Component={<CommonLayout page={<Dashboard />} />} />} />
            <Route path='/studentlist/' element={<Authentication Component={<CommonLayout page={<StudentList />} />} />} />
            <Route path='/studentfile/' element={<Authentication Component={<CommonLayout page={<ImportFile />} />} />} />
            <Route path='/addschool/' element={<Authentication Component={<CommonLayout page={<AddSchool />} />} />} />
            {/* <Route path='/schoollist/' element={<CommonLayout page={<SchoolList />} />} /> */}
            <Route path='/addadmin/' element={<Authentication Component={<CommonLayout page={<AddAdmin />} />} />} />
            <Route path='/assignschooladmin/' element={<Authentication Component={<CommonLayout page={<AssignSchoolAdmin />} />} />} />
            <Route path='/videotestimonial/' element={<Authentication Component={<CommonLayout page={<VideoTestimonial />} />} />} />
            <Route path='/imagetestimonial/' element={<Authentication Component={<CommonLayout page={<ImageTestimonial />} />} />} />
            <Route path='/addtestimonial/' element={<Authentication Component={<CommonLayout page={<AddTestimonial />} />} />} />
            <Route path='/severelist/' element={<Authentication Component={<CommonLayout page={<SevereList />} />} />} />
            <Route path='/studentform/' element={<Authentication Component={<CommonLayout page={<StudentForm />} />} />} />
            {/* <Route path='/adminlist/' element={<CommonLayout page={<AdminList />} />} /> */}
            {/* <Route path='/studentreport/' element={<PDFFile />} /> */}
            <Route path='/studentcard/' element={<Authentication Component={<StudentCard />} />} />
            <Route path='/pdfreport/' element={<Authentication Component={<GetReport />} />} />
        </Routes>
    )
}
