import React, { useState } from 'react'
import loginlogo from "../../assests/images/app-logo.png"
import { Link, useNavigate } from 'react-router-dom'
import UserLogin from '../../api/Login/UserLogin'
import Toaster from '../../components/Toaster'
import Loader from '../../components/Loader'
export default function Login() {
    let navigate = useNavigate()
    let [sendForm, setSendForm] = useState({
        userName: "",
        password: ""
    })
    let [loader, setLoader] = useState(0)
    let handleOnchange = (e) => {
        let name = e.target.name
        let value = e.target.value
        setSendForm((sendform) => ({ ...sendform, [name]: value }))
    }
    let [toaster, setToaster] = useState({
        type: "",
        message: "",
        show: 0
    })
    let handleLogin = async (e) => {
        let response = await UserLogin(sendForm)
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (sendForm.userName.trim() !== "" && sendForm.password.trim() !== "") {
            
            if (emailRegex.test(sendForm.userName)) {
                setLoader(1)
                let response = await UserLogin(sendForm)

                console.log("login", response)
                if (response.status === 1) {
                    // let temp = {
                    //     "responseValue": {
                    //         "id": 4,
                    //         "name": "RT",
                    //         "email": "RT@gmail.com",
                    //         "mobileNo": "9874566541",
                    //         "address": null,
                    //         "pincode": null,
                    //         "cityId": 0,
                    //         "stateId": 0,
                    //         "countryId": 0,
                    //         "password": "123",
                    //         "roleId": 2,
                    //         "userId": 3,
                    //         "createdDate": "2024-04-14T11:06:21.707",
                    //         "status": true
                    //     },
                    //     "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzaWQiOiJkMDJiNjJkMy0zMmYyLTQ5ZTMtODUwYy00NzNjMjQ0YjNiMjEiLCJwcmltYXJ5c2lkIjoiNCIsInVuaXF1ZV9uYW1lIjoiUlQiLCJuYW1lIjoiUlQiLCJqdGkiOiIyNDM0ZWU1YS1hMjk3LTQ0MmYtYjJlMC03NWIxN2U1Y2Y5ZjciLCJuYmYiOjE3MTMxMTAxMjMsImV4cCI6MTcxMzE0NjEyMywiaWF0IjoxNzEzMTEwMTIzLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo3MjM1LyIsImF1ZCI6Imh0dHBzOi8vbG9jYWxob3N0OjcyMzUvIn0.GlqxiSpvguZLjLQchz9MXQreTJvgFnk1c4xTp-XVz0wDqBBw9cmyhnKdwIALxGLFj0zJvNqodBqwdBHBrrXbTQ"
                    // }
                    setLoader(0)

                    window.userId = response.responseValue.userId
                    window.roleId = response.responseValue.roleId
                    window.token = response.token
                    window.sessionStorage.setItem("userData", JSON.stringify(response))
                    navigate("/home/")
                }
                else {
                    setLoader(0)

                    setToaster({
                        type: "alert",
                        message: response.responseValue,
                        show: 1
                    })
                    // let temp = {
                    //     "responseValue": {
                    //         "id": 4,
                    //         "name": "RT",
                    //         "email": "RT@gmail.com",
                    //         "mobileNo": "9874566541",
                    //         "address": null,
                    //         "pincode": null,
                    //         "cityId": 0,
                    //         "stateId": 0,
                    //         "countryId": 0,
                    //         "password": "123",
                    //         "roleId": 2,
                    //         "userId": 3,
                    //         "createdDate": "2024-04-14T11:06:21.707",
                    //         "status": true
                    //     },
                    //     "token": "eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzaWQiOiJkMDJiNjJkMy0zMmYyLTQ5ZTMtODUwYy00NzNjMjQ0YjNiMjEiLCJwcmltYXJ5c2lkIjoiNCIsInVuaXF1ZV9uYW1lIjoiUlQiLCJuYW1lIjoiUlQiLCJqdGkiOiIyNDM0ZWU1YS1hMjk3LTQ0MmYtYjJlMC03NWIxN2U1Y2Y5ZjciLCJuYmYiOjE3MTMxMTAxMjMsImV4cCI6MTcxMzE0NjEyMywiaWF0IjoxNzEzMTEwMTIzLCJpc3MiOiJodHRwczovL2xvY2FsaG9zdDo3MjM1LyIsImF1ZCI6Imh0dHBzOi8vbG9jYWxob3N0OjcyMzUvIn0.GlqxiSpvguZLjLQchz9MXQreTJvgFnk1c4xTp-XVz0wDqBBw9cmyhnKdwIALxGLFj0zJvNqodBqwdBHBrrXbTQ"
                    // }
                    // window.userId = temp.responseValue.userId
                    // window.roleId = temp.responseValue.roleId
                    // window.token = temp.token
                    // window.sessionStorage.setItem("userData", JSON.stringify(temp))
                    // navigate("/home/")
                }
            }
            else {
                setToaster({
                    type: "alert",
                    message: "Enter Right Email!!",
                    show: 1
                })
            }
        }
        else {
            setToaster({
                type: "alert",
                message: "Please Fill All Fields !!",
                show: 1
            })
        }
    }
    let handlekeyDown = (e) => {
        if(e.keyCode === 13)
        {
            handleLogin()
        }
    }
    return (
        <div className="app app-login p-0">
            <div className="row g-0 app-auth-wrapper">
                <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center p-5">
                    <div className="d-flex flex-column align-content-end">
                        <div className="app-auth-body mx-auto">
                            <div className="app-auth-branding mb-4"><Link className="app-logo" to="/"><img className="logo-icon me-2" src={loginlogo} alt="logo" /></Link></div>
                            <h2 className="auth-heading text-center mb-5">Log in to Stufit</h2>
                            <div className="auth-form-container text-start">
                                <div className="auth-form login-form">
                                    <div className="email mb-3">
                                        <label className="sr-only" for="signin-email">Email</label>
                                        <input id="signin-email" name="userName" type="email" className="form-control signin-email" placeholder="Enter Email address" required="required" onChange={handleOnchange} onKeyDown={handlekeyDown} />
                                    </div>
                                    <div className="password mb-3">
                                        <label className="sr-only" for="signin-password">Password</label>
                                        <input id="signin-password" name="password" type="password" className="form-control signin-password" placeholder="Enter Password" required="required" onChange={handleOnchange} onKeyDown={handlekeyDown}/>
                                        {/* <div className="extra mt-3 row justify-content-between">
                                            <div className="col-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value="" id="RememberPassword" />
                                                    <label className="form-check-label" for="RememberPassword">
                                                        Remember me
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="forgot-password text-end">
                                                    <a href="reset-password.html">Forgot password?</a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="text-center">
                                        <button type="button" className="btn app-btn-primary w-100 theme-btn mx-auto" onClick={handleLogin}>Log In</button>
                                    </div>
                                </div>

                                {/* <div className="auth-option text-center pt-5">No Account? Sign up <a className="text-link" href="signup.html" >here</a>.</div> */}
                            </div>

                        </div>

                        {/* <footer className="app-auth-footer">
                            <div className="container text-center py-3">
                                <small className="copyright">Designed with <span className="sr-only">love</span><i className="fas fa-heart" style={{ "color": "#fb866a" }}></i> by <a className="app-link" href="http://themes.3rdwavemedia.com" target="_blank">Xiaoying Riley</a> for developers</small>

                            </div>
                        </footer> */}
                    </div>
                </div>
                <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
                    <div className="auth-background-holder">
                    </div>
                    <div className="auth-background-mask"></div>
                    {/* <div className="auth-background-overlay p-3 p-lg-5">
                        <div className="d-flex flex-column align-content-end h-100">
                            <div className="h-100"></div>
                            <div className="overlay-content p-3 p-lg-4 rounded">
                                <h5 className="mb-3 overlay-title">Explore Portal Admin Template</h5>
                                <div>Portal is a free Bootstrap 5 admin dashboard template. You can download and view the template license <a href="https://themes.3rdwavemedia.com/bootstrap-templates/admin-dashboard/portal-free-bootstrap-admin-dashboard-template-for-developers/">here</a>.</div>
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>

            {
                toaster.show === 1 ?
                    <Toaster show={toaster.show} type={toaster.type} message={toaster.message} close={setToaster} /> : ""
            }

            <Loader val={loader}/>
        </div>
    )
}
