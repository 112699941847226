import React, { useState } from 'react'

export default function StudentForm() {
    const [formData, setFormData] = useState({
        id: '',
        student_Id: '',
        adhar_Number: '',
        first_Name: '',
        last_Name: '',
        school_Id: '',
        school_Name: '',
        gender: '',
        area: '',
        pincode: '',
        street_1: '',
        street_2: '',
        house_No: '',
        email: '',
        contact_Number: '',
        dob: '',
        fathers_Name: '',
        mothers_Name: '',
        class: '',
        section: '',
        siblings: '',
        city: '',
        state: '',
        assessment_Type: '',
        assessment_Date: '',
        assessment_Session: '',
        height: '',
        weight: '',
        bmi: '',
        body_Temprature: '',
        systolic_Blood_Pressure_Mmhg: '',
        diastolic_Blood_Pressure_Mmhg: '',
        blood_Group: '',
        pulse_Rate_Bpm: '',
        oxygen_Saturation: '',
        posture: '',
        upper_Limb_Range_Of_Motion: '',
        neck_Rom: '',
        lower_Limb_Range_Of_Motion: '',
        dystrophy: '',
        atrophy: '',
        endurance: '',
        stamina: '',
        pain: '',
        spasm: '',
        pa_Advice: '',
        pa_Remark: '',
        vision_Right_Eye: '',
        vision_Left_Eye: '',
        refraction: '',
        colour_Vision: '',
        eye_Disease: '',
        squint: '',
        ptosis: '',
        nystagmus: '',
        cataract: '',
        micro_Eye_Ball: '',
        itching: '',
        dryness: '',
        frequent_Tear: '',
        red_Eyes: '',
        yellowish_Eyes: '',
        va_Advice: '',
        va_Remark: '',
        dental_Caries: '',
        orthodontic_Correction: '',
        extraction: '',
        space_Maintainer: '',
        oral_Hygiene_Status: '',
        sugar_Intake: '',
        number_Of_Teeth_Brushing_In_A_Day: '',
        malpositioned_Tooth_Teeth: '',
        da_Advice: '',
        da_Remark: '',
        speech_Milestone: '',
        comprehesion: '',
        expression: '',
        mode_Of_Communication: '',
        audiotory_Skill: '',
        hearing_Loss: '',
        tinnitus: '',
        ear_Perforation: '',
        respond_On_Named_Call: '',
        hearing_Assessment_Result: '',
        ear_Wax: '',
        ear_Pain: '',
        medical_History: '',
        sa_Advice: '',
        sa_Remark: '',
        pale_Or_Yellowish_Skin: '',
        irregular_Heartbeats: '',
        shortness_Of_Breath: '',
        dizziness_Headness: '',
        cold_Hands_Feet: '',
        anemia: '',
        fatigue: '',
        vitamin_D_Deficiency: '',
        obesity: '',
        ideal_Body_Weight: '',
        ideal_Calories_Estimation: '',
        na_Advice: '',
        na_Remark: '',
        chest_Auscultation: '',
        abdominal_Palpitation: '',
        active_Infectious_Disease_Ifany: '',
        cleft_Lip_Palate: '',
        club_Foot: '',
        conginital_Cataract: '',
        conginital_Deafness: '',
        skin_Infection_Ifany: '',
        otitis_Media: '',
        neuromotor_Impairment: '',
        body_Type: '',
        ga_Advice: '',
        ga_Remark: '',
        behaviour: '',
        spm_Percentile: '',
        advice: '',
        severe: '',
        psychologicalAssessment: ''
    });
    const [errors, setErrors] = useState({});

    const validate = () => {
        let errors = {};

        // Validation rules
        if (!formData.student_Id.trim()) errors.student_Id = 'Student ID is required';
        if (!formData.adhar_Number.trim()) errors.adhar_Number = 'Adhar Number is required';
        if (!formData.first_Name.trim()) errors.first_Name = 'First Name is required';
        if (!formData.last_Name.trim()) errors.last_Name = 'Last Name is required';
        if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Valid Email is required';
        if (!formData.pincode.trim() || !/^\d{6}$/.test(formData.pincode)) errors.pincode = 'Pincode must be a 6-digit number';
        if (!formData.contact_Number.trim() || !/^\d{10}$/.test(formData.contact_Number)) errors.contact_Number = 'Contact Number must be a 10-digit number';
        if (!formData.dob.trim() || !/\d{4}-\d{2}-\d{2}/.test(formData.dob)) errors.dob = 'Date of Birth must be in YYYY-MM-DD format';
        if (!formData.height.trim() || isNaN(formData.height)) errors.height = 'Height must be a number';
        if (!formData.weight.trim() || isNaN(formData.weight)) errors.weight = 'Weight must be a number';
        if (!formData.bmi.trim() || isNaN(formData.bmi)) errors.bmi = 'BMI must be a number';
        if (!formData.body_Temprature.trim() || isNaN(formData.body_Temprature)) errors.body_Temprature = 'Body Temperature must be a number';
        if (!formData.systolic_Blood_Pressure_Mmhg.trim() || isNaN(formData.systolic_Blood_Pressure_Mmhg)) errors.systolic_Blood_Pressure_Mmhg = 'Systolic Blood Pressure must be a number';
        if (!formData.diastolic_Blood_Pressure_Mmhg.trim() || isNaN(formData.diastolic_Blood_Pressure_Mmhg)) errors.diastolic_Blood_Pressure_Mmhg = 'Diastolic Blood Pressure must be a number';
        if (!formData.pulse_Rate_Bpm.trim() || isNaN(formData.pulse_Rate_Bpm)) errors.pulse_Rate_Bpm = 'Pulse Rate must be a number';
        if (!formData.oxygen_Saturation.trim() || isNaN(formData.oxygen_Saturation)) errors.oxygen_Saturation = 'Oxygen Saturation must be a number';
        if (!formData.school_Id.trim()) errors.school_Id = 'School ID is required';
        if (!formData.school_Name.trim()) errors.school_Name = 'School Name is required';
        if (!formData.gender.trim()) errors.gender = 'Gender is required';
        if (!formData.area.trim()) errors.area = 'Area is required';
        if (!formData.street_1.trim()) errors.street_1 = 'Street 1 is required';
        if (!formData.street_2.trim()) errors.street_2 = 'Street 2 is required';
        if (!formData.house_No.trim()) errors.house_No = 'House Number is required';
        if (!formData.fathers_Name.trim()) errors.fathers_Name = 'Father\'s Name is required';
        if (!formData.mothers_Name.trim()) errors.mothers_Name = 'Mother\'s Name is required';
        if (!formData.class.trim()) errors.class = 'Class is required';
        if (!formData.section.trim()) errors.section = 'Section is required';
        if (!formData.siblings.trim()) errors.siblings = 'Number of Siblings is required';
        if (!formData.city.trim()) errors.city = 'City is required';
        if (!formData.state.trim()) errors.state = 'State is required';
        if (!formData.assessment_Type.trim()) errors.assessment_Type = 'Assessment Type is required';
        if (!formData.assessment_Date.trim() || !/\d{4}-\d{2}-\d{2}/.test(formData.assessment_Date)) errors.assessment_Date = 'Assessment Date must be in YYYY-MM-DD format';
        if (!formData.assessment_Session.trim()) errors.assessment_Session = 'Assessment Session is required';
        if (!formData.posture.trim()) errors.posture = 'Posture is required';
        if (!formData.upper_Limb_Range_Of_Motion.trim()) errors.upper_Limb_Range_Of_Motion = 'Upper Limb Range of Motion is required';
        if (!formData.neck_Rom.trim()) errors.neck_Rom = 'Neck Range of Motion is required';
        if (!formData.lower_Limb_Range_Of_Motion.trim()) errors.lower_Limb_Range_Of_Motion = 'Lower Limb Range of Motion is required';
        if (!formData.dystrophy.trim()) errors.dystrophy = 'Dystrophy is required';
        if (!formData.atrophy.trim()) errors.atrophy = 'Atrophy is required';
        if (!formData.endurance.trim()) errors.endurance = 'Endurance is required';
        if (!formData.stamina.trim()) errors.stamina = 'Stamina is required';
        if (!formData.pain.trim()) errors.pain = 'Pain is required';
        if (!formData.spasm.trim()) errors.spasm = 'Spasm is required';
        if (!formData.pa_Advice.trim()) errors.pa_Advice = 'PA Advice is required';
        if (!formData.pa_Remark.trim()) errors.pa_Remark = 'PA Remark is required';
        if (!formData.vision_Right_Eye.trim()) errors.vision_Right_Eye = 'Vision Right Eye is required';
        if (!formData.vision_Left_Eye.trim()) errors.vision_Left_Eye = 'Vision Left Eye is required';
        if (!formData.refraction.trim()) errors.refraction = 'Refraction is required';
        if (!formData.colour_Vision.trim()) errors.colour_Vision = 'Colour Vision is required';
        if (!formData.eye_Disease.trim()) errors.eye_Disease = 'Eye Disease is required';
        if (!formData.squint.trim()) errors.squint = 'Squint is required';
        if (!formData.ptosis.trim()) errors.ptosis = 'Ptosis is required';
        if (!formData.nystagmus.trim()) errors.nystagmus = 'Nystagmus is required';
        if (!formData.cataract.trim()) errors.cataract = 'Cataract is required';
        if (!formData.micro_Eye_Ball.trim()) errors.micro_Eye_Ball = 'Micro Eye Ball is required';
        if (!formData.itching.trim()) errors.itching = 'Itching is required';
        if (!formData.dryness.trim()) errors.dryness = 'Dryness is required';
        if (!formData.frequent_Tear.trim()) errors.frequent_Tear = 'Frequent Tear is required';
        if (!formData.red_Eyes.trim()) errors.red_Eyes = 'Red Eyes is required';
        if (!formData.yellowish_Eyes.trim()) errors.yellowish_Eyes = 'Yellowish Eyes is required';
        if (!formData.va_Advice.trim()) errors.va_Advice = 'VA Advice is required';
        if (!formData.va_Remark.trim()) errors.va_Remark = 'VA Remark is required';
        if (!formData.dental_Caries.trim()) errors.dental_Caries = 'Dental Caries is required';
        if (!formData.orthodontic_Correction.trim()) errors.orthodontic_Correction = 'Orthodontic Correction is required';
        if (!formData.extraction.trim()) errors.extraction = 'Extraction is required';
        if (!formData.space_Maintainer.trim()) errors.space_Maintainer = 'Space Maintainer is required';
        if (!formData.oral_Hygiene_Status.trim()) errors.oral_Hygiene_Status = 'Oral Hygiene Status is required';
        if (!formData.sugar_Intake.trim()) errors.sugar_Intake = 'Sugar Intake is required';
        if (!formData.number_Of_Teeth_Brushing_In_A_Day.trim()) errors.number_Of_Teeth_Brushing_In_A_Day = 'Number of Teeth Brushing In A Day is required';
        if (!formData.malpositioned_Tooth_Teeth.trim()) errors.malpositioned_Tooth_Teeth = 'Malpositioned Tooth/Teeth is required';
        if (!formData.da_Advice.trim()) errors.da_Advice = 'DA Advice is required';
        if (!formData.da_Remark.trim()) errors.da_Remark = 'DA Remark is required';
        if (!formData.speech_Milestone.trim()) errors.speech_Milestone = 'Speech Milestone is required';
        if (!formData.comprehesion.trim()) errors.comprehesion = 'Comprehension is required';
        if (!formData.expression.trim()) errors.expression = 'Expression is required';
        if (!formData.mode_Of_Communication.trim()) errors.mode_Of_Communication = 'Mode of Communication is required';
        if (!formData.audiotory_Skill.trim()) errors.audiotory_Skill = 'Audiotory Skill is required';
        if (!formData.hearing_Loss.trim()) errors.hearing_Loss = 'Hearing Loss is required';
        if (!formData.speech_Audio_Advice.trim()) errors.speech_Audio_Advice = 'Speech Audio Advice is required';
        if (!formData.speech_Audio_Remark.trim()) errors.speech_Audio_Remark = 'Speech Audio Remark is required';

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if(value.length === 0)
        {
            setFormData({
                ...formData,
                [name]: value
            });
            return
        }
        // Validation for "pincode" and "contact_Number"
        if (name === "pincode" || name === "contact_Number") {
            if (!/^\d+$/.test(value)) {
                return; // Invalid input
            }
        }
    
        // Validation for "height", "weight", and "bmi"
        else if (name === "height" || name === "weight" || name === "bmi") {
            if (!/^(?:[0-9]|[1-9][0-9]|[1-2][0-9][0-9])(?:\.\d{1,2})?$/.test(value)) {
                return; // Invalid input
            }
        }
    
        // Validation for "body_Temprature", "systolic_Blood_Pressure_Mmhg", "diastolic_Blood_Pressure_Mmhg", and "oxygen_Saturation"
       
        else if (name === "body_Temprature" || name === "systolic_Blood_Pressure_Mmhg" || name === "diastolic_Blood_Pressure_Mmhg" || name === "oxygen_Saturation") {
            if (!/^\d+(\.\d+)?$/.test(value)) {
                return; // Ensure value is a number
            }
            let numValue = parseFloat(value)
            if (name === "body_Temprature") {
                // Body temperature between 97.0 and 100.0 inclusive
                
                console.log("test ", /^\d+(\.\d+)?$/.test(value), numValue)
                if (!/^(?:[01]?\d?\d|2[0-8][0-9]|105)(?:\.\d{1,2})?$/.test(value)) {
                    return; // Invalid body temperature
                }
            } else if (name === "systolic_Blood_Pressure_Mmhg") {
                // Systolic blood pressure between 90 and 180 inclusive
                if (numValue < 90 || numValue > 180) {
                    return; // Invalid systolic blood pressure
                }
            } else if (name === "diastolic_Blood_Pressure_Mmhg") {
                // Diastolic blood pressure between 60 and 120 inclusive
                if (numValue < 60 || numValue > 120) {
                    return; // Invalid diastolic blood pressure
                }
            } else if (name === "oxygen_Saturation") {
                // Oxygen saturation between 0 and 100 inclusive
                if (numValue < 0 || numValue > 100) {
                    return; // Invalid oxygen saturation
                }
            }
        }

    
        // Update state if all validations pass
        setFormData({
            ...formData,
            [name]: value
        });
    };
    
    let handleSave = () => { }

    return (
        <>
            <div class="row g-3 mb-4 align-items-center justify-content-between">
                <div class="col-auto">
                    <h1 class="app-page-title mb-0">Add New Student</h1>
                </div>
            </div>
            <div class="app-card app-card-orders-table shadow-sm mb-5">
                <div class="app-card-body p-3">
                    <div className='mt-3'>


                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" for="student_Id">Student ID</label>
                                    <input id="student_Id" name="student_Id" value={formData.student_Id} type="text" className="form-control" placeholder="Enter Student ID" required="required" onChange={handleChange} />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" for="adhar_Number">Aadhar Number</label>
                                    <input id="adhar_Number" name="adhar_Number" type="text" className="form-control" placeholder="Enter Aadhar Number" required="required" onChange={handleChange} />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="first_Name">First Name</label>
                                    <input
                                        id="first_Name"
                                        name="first_Name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter First Name"
                                        required="required"
                                        value={formData.first_Name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="last_Name">Last Name</label>
                                    <input
                                        id="last_Name"
                                        name="last_Name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Last Name"
                                        required="required"
                                        value={formData.last_Name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="school_Id">School ID</label>
                                    <input
                                        id="school_Id"
                                        name="school_Id"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter School ID"
                                        required="required"
                                        value={formData.school_Id}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="school_Name">School Name</label>
                                    <input
                                        id="school_Name"
                                        name="school_Name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter School Name"
                                        required="required"
                                        value={formData.school_Name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="gender">Gender</label>
                                    <select
                                        className="form-select w-100"
                                        id="gender"
                                        name="gender"
                                        value={formData.gender}
                                        required="required"
                                        onChange={handleChange}
                                    >
                                        <option value="-1">Select Gender</option>
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="area">Area</label>
                                    <input
                                        id="area"
                                        name="area"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Area"
                                        required="required"
                                        value={formData.area}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="pincode">Pincode</label>
                                    <input
                                        id="pincode"
                                        name="pincode"
                                        type="text"
                                        maxLength="6"
                                        className="form-control"
                                        placeholder="Enter Pincode"
                                        required="required"
                                        value={formData.pincode}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="street_1">Street 1</label>
                                    <input
                                        id="street_1"
                                        name="street_1"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Street 1"
                                        required="required"
                                        value={formData.street_1}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>



                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="street_2">Street 2</label>
                                    <input
                                        id="street_2"
                                        name="street_2"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Street 2"
                                        value={formData.street_2}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="house_No">House No</label>
                                    <input
                                        id="house_No"
                                        name="house_No"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter House No"
                                        required="required"
                                        value={formData.house_No}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="email">Email</label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        required="required"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="contact_Number">Contact Number</label>
                                    <input
                                        id="contact_Number"
                                        name="contact_Number"
                                        type="text"
                                        maxLength={10}
                                        className="form-control"
                                        placeholder="Enter Contact Number"
                                        required="required"
                                        value={formData.contact_Number}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="dob">Date of Birth</label>
                                    <input
                                        id="dob"
                                        name="dob"
                                        type="date"
                                        className="form-control"
                                        placeholder="Enter Date of Birth"
                                        required="required"
                                        value={formData.dob}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="fathers_Name">Father's Name</label>
                                    <input
                                        id="fathers_Name"
                                        name="fathers_Name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Father's Name"
                                        required="required"
                                        value={formData.fathers_Name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>




                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="mothers_Name">Mother's Name</label>
                                    <input
                                        id="mothers_Name"
                                        name="mothers_Name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Mother's Name"
                                        required="required"
                                        value={formData.mothers_Name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="class">Class</label>
                                    <input
                                        id="class"
                                        name="class"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Class"
                                        required="required"
                                        value={formData.class}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="section">Section</label>
                                    <input
                                        id="section"
                                        name="section"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Section"
                                        required="required"
                                        value={formData.section}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="siblings">Siblings</label>
                                    <input
                                        id="siblings"
                                        name="siblings"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Siblings Information"
                                        value={formData.siblings}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="state">State</label>
                                    <select
                                        className="form-select w-100"
                                        id="state"
                                        name="state"
                                        value={formData.state}
                                        onChange={handleChange}
                                    >
                                        <option value="-1">Select State</option>
                                        <option value="1">Uttar Pradesh</option>
                                        <option value="2">Delhi</option>
                                        <option value="3">Mumbai</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="city">City</label>
                                    <select
                                        className="form-select w-100"
                                        id="city"
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                    >
                                        <option value="-1">Select City</option>
                                        <option value="1">Lucknow</option>
                                        <option value="2">Kanpur</option>
                                        <option value="3">Aligarh</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="assessment_Type">Assessment Type</label>
                                    <input
                                        id="assessment_Type"
                                        name="assessment_Type"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Assessment Type"
                                        required="required"
                                        value={formData.assessment_Type}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="assessment_Date">Assessment Date</label>
                                    <input
                                        id="assessment_Date"
                                        name="assessment_Date"
                                        type="date"
                                        className="form-control"
                                        required="required"
                                        value={formData.assessment_Date}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="assessment_Session">Assessment Session</label>
                                    <input
                                        id="assessment_Session"
                                        name="assessment_Session"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Assessment Session"
                                        required="required"
                                        value={formData.assessment_Session}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="height">Height (cm)</label>
                                    <input
                                        id="height"
                                        name="height"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Height"
                                        required="required"
                                        value={formData.height}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="weight">Weight</label>
                                    <input
                                        id="weight"
                                        name="weight"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Weight"
                                        required="required"
                                        value={formData.weight}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="bmi">BMI</label>
                                    <input
                                        id="bmi"
                                        name="bmi"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter BMI"
                                        required="required"
                                        value={formData.bmi}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="body_Temprature">Body Temperature</label>
                                    <input
                                        id="body_Temprature"
                                        name="body_Temprature"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Body Temperature"
                                        required="required"
                                        value={formData.body_Temprature}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="systolic_Blood_Pressure_Mmhg">Systolic Blood Pressure (mmHg)</label>
                                    <input
                                        id="systolic_Blood_Pressure_Mmhg"
                                        name="systolic_Blood_Pressure_Mmhg"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Systolic Blood Pressure"
                                        required="required"
                                        value={formData.systolic_Blood_Pressure_Mmhg}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="diastolic_Blood_Pressure_Mmhg">Diastolic Blood Pressure (mmHg)</label>
                                    <input
                                        id="diastolic_Blood_Pressure_Mmhg"
                                        name="diastolic_Blood_Pressure_Mmhg"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Diastolic Blood Pressure"
                                        required="required"
                                        value={formData.diastolic_Blood_Pressure_Mmhg}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="blood_Group">Blood Group</label>
                                    <input
                                        id="blood_Group"
                                        name="blood_Group"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Blood Group"
                                        required="required"
                                        value={formData.blood_Group}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="father_Qualification">Father's Qualification</label>
                                    <input
                                        id="father_Qualification"
                                        name="father_Qualification"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Father's Qualification"
                                        required="required"
                                        value={formData.father_Qualification}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="mother_Qualification">Mother's Qualification</label>
                                    <input
                                        id="mother_Qualification"
                                        name="mother_Qualification"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Mother's Qualification"
                                        required="required"
                                        value={formData.mother_Qualification}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="father_Occupation">Father's Occupation</label>
                                    <input
                                        id="father_Occupation"
                                        name="father_Occupation"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Father's Occupation"
                                        required="required"
                                        value={formData.father_Occupation}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="mother_Occupation">Mother's Occupation</label>
                                    <input
                                        id="mother_Occupation"
                                        name="mother_Occupation"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Mother's Occupation"
                                        required="required"
                                        value={formData.mother_Occupation}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="admission_Date">Admission Date</label>
                                    <input
                                        id="admission_Date"
                                        name="admission_Date"
                                        type="date"
                                        className="form-control"
                                        required="required"
                                        value={formData.admission_Date}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="remarks">Remarks</label>
                                    <textarea
                                        id="remarks"
                                        name="remarks"
                                        className="form-control"
                                        placeholder="Enter Remarks"
                                        rows="3"
                                        value={formData.remarks}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="observations">Observations</label>
                                    <input
                                        id="observations"
                                        name="observations"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Observations"
                                        value={formData.observations}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="vision">Vision</label>
                                    <input
                                        id="vision"
                                        name="vision"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Vision Details"
                                        value={formData.vision}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="vision_Left_Eye_Without_Glasses">Vision Left Eye Without Glasses</label>
                                    <input
                                        id="vision_Left_Eye_Without_Glasses"
                                        name="vision_Left_Eye_Without_Glasses"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Vision Left Eye (Without Glasses)"
                                        value={formData.vision_Left_Eye_Without_Glasses}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="vision_Left_Eye_With_Glasses">Vision Left Eye With Glasses</label>
                                    <input
                                        id="vision_Left_Eye_With_Glasses"
                                        name="vision_Left_Eye_With_Glasses"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Vision Left Eye (With Glasses)"
                                        value={formData.vision_Left_Eye_With_Glasses}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="vision_Right_Eye_Without_Glasses">Vision Right Eye Without Glasses</label>
                                    <input
                                        id="vision_Right_Eye_Without_Glasses"
                                        name="vision_Right_Eye_Without_Glasses"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Vision Right Eye (Without Glasses)"
                                        value={formData.vision_Right_Eye_Without_Glasses}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="vision_Right_Eye_With_Glasses">Vision Right Eye With Glasses</label>
                                    <input
                                        id="vision_Right_Eye_With_Glasses"
                                        name="vision_Right_Eye_With_Glasses"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Vision Right Eye (With Glasses)"
                                        value={formData.vision_Right_Eye_With_Glasses}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="hearing_Left_Ear">Hearing Left Ear</label>
                                    <input
                                        id="hearing_Left_Ear"
                                        name="hearing_Left_Ear"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Hearing Left Ear"
                                        value={formData.hearing_Left_Ear}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="hearing_Right_Ear">Hearing Right Ear</label>
                                    <input
                                        id="hearing_Right_Ear"
                                        name="hearing_Right_Ear"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Hearing Right Ear"
                                        value={formData.hearing_Right_Ear}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="dental">Dental</label>
                                    <input
                                        id="dental"
                                        name="dental"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Dental Information"
                                        value={formData.dental}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="teeth_Cleaning">Teeth Cleaning</label>
                                    <input
                                        id="teeth_Cleaning"
                                        name="teeth_Cleaning"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Teeth Cleaning Info"
                                        value={formData.teeth_Cleaning}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="deformities">Deformities</label>
                                    <input
                                        id="deformities"
                                        name="deformities"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Any Deformities"
                                        value={formData.deformities}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="physical_Disability">Physical Disability</label>
                                    <input
                                        id="physical_Disability"
                                        name="physical_Disability"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Any Physical Disability"
                                        value={formData.physical_Disability}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="mental_Disability">Mental Disability</label>
                                    <input
                                        id="mental_Disability"
                                        name="mental_Disability"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Any Mental Disability"
                                        value={formData.mental_Disability}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="care_Required">Care Required</label>
                                    <input
                                        id="care_Required"
                                        name="care_Required"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Any Care Required"
                                        value={formData.care_Required}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="operations">Operations</label>
                                    <input
                                        id="operations"
                                        name="operations"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Any Operations"
                                        value={formData.operations}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="care">Care</label>
                                    <input
                                        id="care"
                                        name="care"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Any Care"
                                        value={formData.care}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="first_Aid">First Aid</label>
                                    <input
                                        id="first_Aid"
                                        name="first_Aid"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Any First Aid"
                                        value={formData.first_Aid}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="medication">Medication</label>
                                    <input
                                        id="medication"
                                        name="medication"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Any Medication"
                                        value={formData.medication}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="health">Health</label>
                                    <input
                                        id="health"
                                        name="health"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Health Information"
                                        value={formData.health}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="immunization">Immunization</label>
                                    <input
                                        id="immunization"
                                        name="immunization"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Immunization Info"
                                        value={formData.immunization}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>






                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="family_history">Family History</label>
                                    <input
                                        id="family_history"
                                        name="family_history"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Family Medical History"
                                        value={formData.family_history}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="travel_history">Travel History</label>
                                    <input
                                        id="travel_history"
                                        name="travel_history"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Travel History"
                                        value={formData.travel_history}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="immunization">Immunization</label>
                                    <input
                                        id="immunization"
                                        name="immunization"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Immunization Details"
                                        value={formData.immunization}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="dietary_habits">Dietary Habits</label>
                                    <input
                                        id="dietary_habits"
                                        name="dietary_habits"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Dietary Habits"
                                        value={formData.dietary_habits}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="lifestyle">Lifestyle</label>
                                    <input
                                        id="lifestyle"
                                        name="lifestyle"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Lifestyle Information"
                                        value={formData.lifestyle}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="exercise">Exercise</label>
                                    <input
                                        id="exercise"
                                        name="exercise"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Exercise Routine"
                                        value={formData.exercise}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="sleep">Sleep</label>
                                    <input
                                        id="sleep"
                                        name="sleep"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Sleep Habits"
                                        value={formData.sleep}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="stress_levels">Stress Levels</label>
                                    <input
                                        id="stress_levels"
                                        name="stress_levels"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Stress Levels"
                                        value={formData.stress_levels}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="mental_health">Mental Health</label>
                                    <input
                                        id="mental_health"
                                        name="mental_health"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Mental Health Details"
                                        value={formData.mental_health}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className="mb-3">
                                    <label className="sr-only" htmlFor="emotional_wellbeing">Emotional Wellbeing</label>
                                    <input
                                        id="emotional_wellbeing"
                                        name="emotional_wellbeing"
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Emotional Wellbeing"
                                        value={formData.emotional_wellbeing}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className=' d-flex justify-content-end'>
                            <div className="text-center w-25 ">
                                <button type="button" className="btn app-btn-primary w-100 theme-btn mx-auto" onClick={handleSave}>Save</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
