async function GetSchoolListByAdmin(obj) {
    let id = window.sessionStorage.getItem("userData") ? JSON.parse(window.sessionStorage.getItem("userData")).responseValue.id : 1
    let url = window.BaseUrl + `/api/Users/GetSchoolListByAdmin?adminId=${id}&roleId=${window.roleId}`;
    let head = {
        'Content-Type': 'application/json',
        accept: '*/*',
        Authorization:"Bearer "+window.token
    }
    try {
        let response =
            await fetch(url, {
                method: 'GET',
                headers: head,
                // body: JSON.stringify(obj)
            })
                .then((res) => res.json())
                .then(data => data)
                .catch(error => error)
        return response;
    }
    catch (e) {
        return { status: 0, message: "Server Down!!" }
    }

}
export default GetSchoolListByAdmin;