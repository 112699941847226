async function UploadAssessment(obj, file) {
    let url = window.BaseUrl + `/api/Users/UploadAssessment?studentId=${obj.studentId}&IsRegular=${obj.IsRegular}`;
    let head = {
        // 'Content-Type': 'application/json',
        Authorization: "Bearer " + window.token,
        // 'Accept': '*/*',
    }
    try {
        let response =
            await fetch(url, {
                method: 'POST',
                headers: head,
                body: file
            })
                .then((res) => res.json())
                .then(data => data)
                .catch(error => error)
        return response;
    }
    catch (e) {
        return { status: 0, message: "Server Down!!" }
    }

}
export default UploadAssessment;